import React from 'react'
import Sponsors from '../content/Sponsors'

import { mapIndexed } from '../lib/Helpers'

export default ({ lang }) => {
  const { title_en, title_es, sponsors } = Sponsors

  return (
    <section className="section--sponsors">
      <div className="container animate__animated animate__fadeInDown fade--in">
        <h1
          className="color--black text--center text--uppercase"
          dangerouslySetInnerHTML={{
            __html: lang === 'en' ? title_en : title_es,
          }}
        />
        <div className="section--sponsors--grid">
          {mapIndexed((item, index) => {
            return (
              <img
                src={require(`../assets/images/sponsors/${item}`)}
                alt="Sponsor Logo"
              />
            )
          })(sponsors)}
        </div>
      </div>
    </section>
  )
}
