import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  fab,
  faFacebook,
  faInstagram,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(fab)

export default ({ lang }) => {
  return (
    <footer>
      <div className="container">
        <div className="social">
          <a href="https://facebook.com/KeepLVOpen" className="social_icon">
            <FontAwesomeIcon icon={faFacebook} mask={['circle']} size="2x" />
          </a>
          <a href="https://instagram.com/KeepLVOpen" className="social_icon">
            <FontAwesomeIcon icon={faInstagram} mask={['circle']} size="2x" />
          </a>
          <a href="https://twitter.com/KeepLVOpen" className="social_icon">
            <FontAwesomeIcon icon={faTwitter} mask={['circle']} size="2x" />
          </a>
        </div>
        {lang === 'en' ? (
          <>
            <div className="row--center">
              <p className="text--center">
                Content brought to you by{' '}
                <img
                  src={require('../assets/images/lvcva-logo.svg')}
                  alt="LVCVA logo"
                  height="50"
                />
              </p>
            </div>
            <p>
              Click{' '}
              <a
                href="https://www.lvcva.com/who-we-are/"
                target="_blank"
                rel="noreferrer noopener"
                className="color--white"
              >
                here
              </a>{' '}
              to learn more about the LVCVA.
            </p>
          </>
        ) : (
          <>
            <div className="row--center">
              <p className="text--center">
                Contenido producido por{' '}
                <img
                  src={require('../assets/images/lvcva-logo.svg')}
                  alt="LVCVA logo"
                  height="50"
                />
              </p>
            </div>
            <p>
              Haz clic{' '}
              <a
                href="https://www.lvcva.com/who-we-are/"
                target="_blank"
                rel="noreferrer noopener"
                className="color--white"
              >
                aqu&iacute;
              </a>{' '}
              para m&aacute;s informaci&oacute;n sobre LVCVA.
            </p>
          </>
        )}
        <p className="text--center">
          &copy; {new Date().getFullYear()} Las Vegas Convention and Visitors
          Authority. All rights reserved.
        </p>
      </div>
    </footer>
  )
}
