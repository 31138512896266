import React from 'react'

import { mapIndexed } from '../lib/Helpers'
import SectionVegasSmartEN from '../content/SectionVegasSmartEN'
import SectionVegasSmartES from '../content/SectionVegasSmartES'

export default ({ lang }) => {
  const { title, intro, site_url, cta } =
    lang === 'en' ? SectionVegasSmartEN : SectionVegasSmartES

  return (
    <section className="section--vegas-smart">
      <div className="container animate__animated animate__fadeIn">
        <div className="intro">
          <h1
            className="color--black intro--title text--uppercase"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p
            className="color--black intro--text"
            dangerouslySetInnerHTML={{ __html: intro }}
          />
          <div className="btn--wrapper text--center">
            <a
              href={site_url}
              target="_blank"
              rel="noreferrer noopener"
              className="btn btn--yellow text--center"
            >
              {lang === 'en' ? 'Visit Site' : 'Visita el Sitio Web'}
            </a>
          </div>
        </div>
        <div className="cta cta--container">
          {mapIndexed((item, index) => {
            return (
              <div className="cta__single">
                <img
                  src={require(`../assets/images/${item.icon}`)}
                  alt={item.title}
                  className="cta__single__img"
                />
                <h3 className="cta__single__title color--black">
                  {item.title}
                </h3>
                <p className="cta__single__text color--black">{item.text}</p>
              </div>
            )
          })(cta)}
        </div>
      </div>
    </section>
  )
}
