import React from 'react'

import IntroContentEN from '../content/IntroContentEN'
import IntroContentES from '../content/IntroContentES'

export default ({ lang }) => {
  const { title, content } = lang === 'en' ? IntroContentEN : IntroContentES

  return (
    <section className="section--intro">
      <div className="container animate__animated animate__fadeInDown fade--in">
        <div className="content">
          <h1
            className="color--white text--center text--uppercase"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p className="section--intro__text text--center">{content}</p>
        </div>
      </div>
    </section>
  )
}
