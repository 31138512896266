import React from 'react'
import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  fab,
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

import { mapIndexed } from '../lib/Helpers'
import HeroContentEN from '../content/HeroContentEN'
import HeroContentES from '../content/HeroContentES'

library.add(fab)

export default (props) => {
  const settings = {
    arrows: false,
    dots: true,
    dotsClass: 'slick-dots hero-dots',
    cssEase: 'linear',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <div className="hero-slider ">
          <div className="social">
            <a href="https://facebook.com/KeepLVOpen" className="social_icon">
              <FontAwesomeIcon color="black" icon={faFacebook} mask={['circle']} size="2x" />
            </a>
            <a href="https://instagram.com/KeepLVOpen" className="social_icon">
              <FontAwesomeIcon color="black" icon={faInstagram} mask={['circle']} size="2x" />
            </a>
            <a href="https://twitter.com/KeepLVOpen" className="social_icon">
              <FontAwesomeIcon color="black" icon={faTwitter} mask={['circle']} size="2x" />
            </a>
          </div>
      <Slider
        {...settings}
        className="container animate__animated animate__fadeIn"
      >
        {mapIndexed((item, index) => {
          return (
            <div
              className={`hero-slider--item ${
                item.slide_image ? 'two-columns' : 'one-column'
              }`}
              key={index}
            >
              <div className="hero-slider--item__content">
                <h1 className="color--black text--uppercase">
                  {item.slide_title}
                </h1>
              </div>
              {item.slide_image && (
                <div className="hero-slider--item__img">
                  <img
                    src={require(`../assets/images/${item.slide_image}`)}
                    alt={item.slide_title}
                  />
                </div>
              )}
            </div>
          )
        })(props.lang === 'en' ? HeroContentEN : HeroContentES)}
      </Slider>
    </div>
  )
}
