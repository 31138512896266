import React from 'react'

import SectionPhoneEN from '../content/SectionPhoneEN'
import SectionPhoneES from '../content/SectionPhoneES'

import { isMobile } from 'react-device-detect'

export default ({ lang }) => {
  const {
    title,
    content,
    google_link,
    apple_link,
    learn_more_url,
    section_image,
    section_image_mobile,
  } = lang === 'en' ? SectionPhoneEN : SectionPhoneES

  return (
    <section className="section--phone animate__animated animate__fadeInUpBig fade--in">
      <div className="container">
        <div className="content">
          <div className="content__text">
            <h1
              className="color--black text--uppercase"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p
              className="color--black"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className="content__store-buttons">
              <a
                href={google_link}
                target="_blank"
                rel="noreferrer noopener"
                className="animate__animated color--black"
              >
                <img
                  src={require(`../assets/images/google-play-btn.png`)}
                  alt="google play button"
                />
              </a>
              <a href={apple_link} target="_blank" rel="noreferrer noopener">
                <img
                  src={require(`../assets/images/apple-store-btn.png`)}
                  alt="apple store button"
                />
              </a>
            </div>
            <a
              href={learn_more_url}
              target="_blank"
              rel="noreferrer noopener"
              className="animate__animated"
            >
              {lang === 'en' ? 'Learn More' : 'Descubre Más'}
            </a>
          </div>
          <div className="content__image">
            <img
              src={require(`../assets/images/${section_image}`)}
              alt="iphone with the covid trace app logo"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
