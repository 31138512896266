import React from 'react'

import { mapIndexed } from '../lib/Helpers'
import SectionSNHDEN from '../content/SectionSNHDEN'
import SectionSNHDES from '../content/SectionSNHDES'

export default ({ lang }) => {
  const { title, intro, site_url, cta } =
    lang === 'en' ? SectionSNHDEN : SectionSNHDES
  return (
    <section className="section--snhd">
      <div className="container animate__animated animate__fadeIn">
        <div className="intro">
          <h1
            className="color--white intro--title text--uppercase"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p
            className="color--white intro--text"
            dangerouslySetInnerHTML={{ __html: intro }}
          />
          <div className="btn--wrapper text--center">
            <a
              href={site_url}
              target="_blank"
              rel="noreferrer noopener"
              className="btn btn--yellow text--center"
            >
              {lang === 'en' ? 'Visit Site' : 'Visita el Sitio Web'}
            </a>
          </div>
        </div>
        <div className="cta cta--container">
          {mapIndexed((item, index) => {
            return (
              <div className="cta__card card--white">
                <img
                  src={require(`../assets/images/${item.icon}`)}
                  alt={item.title}
                  className="cta__card__img"
                />
                {item.url ? (
                  <a href={item.url} target="_blank" rel="noreferrer noopener">
                    <h3
                      className="cta__card__title color--black"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                  </a>
                ) : (
                  <h3
                    className="cta__card__title color--black"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                )}
              </div>
            )
          })(cta)}
        </div>
      </div>
    </section>
  )
}
